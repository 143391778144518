import { api } from '../api';
import { setUser } from './authSlice';

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query(body) {
                return {
                    url: 'auth/local/',
                    method: 'POST',
                    body
                }
            },
            transformResponse: (response, meta, arg) => response,
            transformErrorResponse: (response, meta, arg) => {
                const error = new Error(response.data.message);
                error.status = response.status;
                return error;
            },
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                try {
                    const { data: { jwt, user } } = await queryFulfilled;
                    //console.log(user);
                    if (user) {
                        const userInfo = {
                            ...user,
                            jwt
                        }
                        dispatch(setUser(userInfo));
                        localStorage.removeItem('cfUser');
                        localStorage.setItem("cfUser", JSON.stringify(userInfo));
                    }

                } catch (error) {
                    //console.log('onQuery', error);
                }
            },
        }),
        signUpUser: builder.mutation({
            query(body) {
                return {
                    url: '/accounts/signUp',
                    method: 'POST',
                    body
                }
            },
            transformErrorResponse: (response, meta, arg) => {
                const { status, message } = response?.data
                const error = new Error(message);
                error.status = status;
                return error;
            },
        }),
        forgotPassword: builder.mutation({
            query: payload => ({
                url: '/accounts/forgot-password',
                method: 'POST',
                body: payload
            }),
        }),
        forgotPasswordChange: builder.mutation({
            query: payload => ({
                url: '/accounts/forgot-password-change',
                method: 'POST',
                body: payload
            }),
        }),
        changePassword: builder.mutation({
            query: payload => ({
                url: '/accounts/change-password',
                method: 'POST',
                body: payload
            }),
        }),
        verifyEmail: builder.mutation({
            query: payload => ({
                url: '/accounts/verify',
                method: 'POST',
                body: payload
            }),
        }),
    })
})

export const { useLoginUserMutation, useSignUpUserMutation, useForgotPasswordMutation, useForgotPasswordChangeMutation, useChangePasswordMutation, useVerifyEmailMutation } = authApi;