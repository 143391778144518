import { Navigate, Outlet, useLocation } from 'react-router-dom';

import React from 'react'
import { useSelector } from 'react-redux';

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation()
    const userInfo = useSelector(state => state.auth?.userInfo)

    return userInfo ? <Outlet />
        : <Navigate to={"/login"} state={{ from: location }} replace />

}

export default RequireAuth;