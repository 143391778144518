import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className='copy-wrap'>
        <h1>Page Not Found</h1>
        <p>Sorry, the page you're looking for does not exist.</p>
      </div>
      <Link to="/" className="cta-button">Go to Home</Link>
    </div>
  );
};

export default NotFound;
