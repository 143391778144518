import React from "react";
import Shape from "../assets/images/07/bg shape.png";

const Footer = () => {

  return (
    <footer>
      <img
        className="footer-bg-shape"
        src={Shape}
        alt=""
      />
      <div className="footer-wrapper container">
        <div className="wrapper-item">
          <h4>For more details send an email to</h4>
          <h4 className="email-id"><a href="mailto:contact@coastfund.xyz">contact@coastfund.xyz</a></h4>
        </div>
        <ul className="wrapper-item-1">
          {/* <li>
            <a
              href="#faqs"
              onClick={() => smoothScroll("faqs")}
              className="nav-link"
            >
              FAQs
            </a>
          </li>
          <li>
            <a
              href="#slider-portfolio"
              onClick={() => smoothScroll("slider-portfolio")}
              className="nav-link"
            >
              Our Portfolio
            </a>
          </li>
          <li>
            <a
              href="#team"
              onClick={() => smoothScroll("team")}
              className="nav-link"
            >
              Our Team
            </a>
          </li>
          <li>
            <a
              href="#about"
              onClick={() => smoothScroll("about")}
              className="nav-link"
            >
              Why Us
            </a>
          </li> */}
          {/* <li><a href="">Apply Now</a></li> */}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
