import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "store/auth/authSlice";
import Logo from "../assets/images/01/logo-04.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.auth?.userInfo);


  return (
    <>
      <header id="section1">
        <nav id="navbar" className="header-main container">
          <Link to="/" className="brand">
            <img id="logo-brand" src={Logo} alt="" />
          </Link>

          <ul className={`nav-menu ${menuOpen ? "active" : ""}`}>
            {/* <li className="nav-item">
              <a
                href="#about"
                onClick={() => smoothScroll("about")}
                className="nav-link"
              >
                Why Us
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#team"
                onClick={() => smoothScroll("team")}
                className="nav-link"
              >
                Our Team
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#slider-portfolio"
                onClick={() => smoothScroll("slider-portfolio")}
                className="nav-link"
              >
                Our Portfolio
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#faqs"
                onClick={() => smoothScroll("faqs")}
                className="nav-link"
              >
                FAQs
              </a>
            </li> */}
            {userInfo ? (
              <button
                className="logout-btn"
                onClick={() => {
                  dispatch(logout());
                  navigate("/login");
                  setMenuOpen(false);
                }}
              >
                Log out &nbsp;<FontAwesomeIcon icon={faSignOut} />
              </button>
            ) : null}
          </ul>
          <div
            className={`hamburger ${menuOpen ? "active" : ""}`}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span className="bar bar-1"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
