import { Navigate, Route, Routes } from "react-router-dom";

import Agencies from "pages/Agencies";
import Agency from "pages/Agency";
import Layout from "pages/Layout";
import Login from "pages/Login";
import NotFound from "pages/NotFound";
import ResetPassword from "pages/ResetPassword";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import 'react-tabs/style/react-tabs.css';
import { loadUserFromStorage } from "store/auth/authSlice";
import './assets/scss/main.scss';
import RequireAuth from "components/RequireAuth";
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth?.userInfo);

  useEffect(() => {
    dispatch(loadUserFromStorage());
  }, [dispatch]);

  return (
    <Routes >
      <Route path='/' element={<Layout />}>
        <Route path='/login' element={<Login />} />
        <Route path='/reset-password' element={<ResetPassword />} />

        {!userInfo && (
          <Route path='/' element={<Navigate to='/login' replace />} />
        )}

        {userInfo && (
          <Route element={<RequireAuth allowedRoles={["admin"]} />} >
            <Route path='/' element={<Navigate to='/agencies' replace />} />
            <Route path='/agencies/:id' element={<Agency />} />
          </Route>
        )}

        <Route path='/agencies' element={<Agencies />} />

        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
