import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Select from "react-select";
import {
  useGetCapabilitiesQuery,
  useGetCountriesQuery,
  useGetSpecializationQuery,
  useGetIndustriesQuery,
} from "store/helper/helperApi";
import { useGetAgenciesQuery } from "store/agencies/agenciesApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function Agencies() {

  /** SEARCH */

  const [searchQuery, setSearchQuery] = useState("");
  const [filterQuery, setfilterQuery] = useState("");

  useEffect(() => {
    const debouceHandler = setTimeout(() => {
      let queryString = `&filters[title][$containsi]=${searchQuery}`;
      if (!!searchQuery && searchQuery?.length > 2) {
        setfilterQuery(queryString);
      } else {
        setfilterQuery('');
      }
    }, 500);

    return () => {
      clearTimeout(debouceHandler);
    };
  }, [searchQuery]);

  const [selectedCountries, setselectedCountries] = useState([]);
  const [selectedCapabilities, setselectedCapabilities] = useState([]);
  const [selectedSpecializations, setselectedSpecializations] = useState([]);
  const [selectedIndustries, setselectedIndustries] = useState([]);

  const handleToggleActiveCapabilities = (index) => {
    setselectedCapabilities((prevState) => {
      if (prevState.includes(index)) {
        return prevState.filter((i) => i !== index);
      } else {
        return [...prevState, index];
      }
    });
  };

  const handleToggleActiveIndustries = (index) => {
    setselectedIndustries((prevState) => {
      if (prevState.includes(index)) {
        return prevState.filter((i) => i !== index);
      } else {
        return [...prevState, index];
      }
    });
  };

  const handleToggleActiveSpecializations = (index) => {
    setselectedSpecializations((prevState) => {
      if (prevState.includes(index)) {
        return prevState.filter((i) => i !== index);
      } else {
        return [...prevState, index];
      }
    });
  };

  const resetFilters = () => {
    setselectedCapabilities([]);
    setselectedIndustries([]);
    setselectedSpecializations([]);
    setselectedCountries([]);
    setfilterQuery("");
    setSearchQuery("");
  };

  /** QUERIES */

  const { data: agencies, isAgenciesDataLoading, isFetching: isAgenciesDataFetching } = useGetAgenciesQuery({
    query: filterQuery,
  });
  const { data: countries, isCountriesDataLoading } = useGetCountriesQuery();
  const { data: capabilities, isCapabilitiesDataLoading } =
    useGetCapabilitiesQuery();
  const { data: specializations, isSpecializationsDataLoading } =
    useGetSpecializationQuery();
  const { data: industries, isIndustriesDataLoading } = useGetIndustriesQuery();

  const countryOptions = countries?.map((country, index) => {
    return {
      value: country?.attributes?.title,
      label: country?.attributes?.title,
    };
  });

  const agencyList = agencies?.map((agency, index) => {
    return (
      <a
        href={`agencies/${agency?.id}`}
        className="view-link"
        key={`agency-box_${index}`}
      >
        <div className="agency-wrapper">
          <div className="adress-wrapper">
            <h3>{agency?.attributes?.title}</h3>
            <p>{agency?.attributes?.location?.split("\n\n")?.[0]}</p>
          </div>
          <div className="view-copy">view more</div>
        </div>
      </a>
    );
  });

  const handleApplyFilters = () => {
    //console.log({ selectedCapabilities, selectedIndustries, selectedSpecializations });

    const activeCapabilities = capabilities
      .filter((_, index) => selectedCapabilities.includes(index))
      .map((capability) => capability.attributes.title);

    const activeIndustries = industries
      .filter((_, index) => selectedIndustries.includes(index))
      .map((industry) => industry.attributes.title);

    const activeSpecializations = specializations
      .filter((_, index) => selectedSpecializations.includes(index))
      .map((specializations) => specializations.attributes.title);

    const activeCountries = selectedCountries?.map(
      (country, index) => country.value
    );

    // console.log({
    //     activeCapabilities,
    //     activeIndustries,
    //     activeSpecializations,
    //     activeCountries,
    // });

    let baseUrl = "";

    if (activeCapabilities) {
      activeCapabilities?.map((capability, index) => {
        baseUrl += `&filters[capabilities][title][$eqi]=${capability}`;
      });
    }
    if (activeIndustries) {
      activeIndustries?.map((industry, index) => {
        baseUrl += `&filters[industries][title][$eqi]=${industry}`;
      });
    }
    if (activeSpecializations) {
      activeSpecializations?.map((specialization, index) => {
        baseUrl += `&filters[specializations][title][$eqi]=${specialization}`;
      });
    }
    if (activeCountries) {
      activeCountries?.map((country, index) => {
        baseUrl += `&filters[countries][title][$eqi]=${country}`;
      });
    }

    setfilterQuery(baseUrl);
  };

  return (
    <div className="agency-partner-section">
      <div className="agps-wrapper container">
        <div className="search-component">
          <h1 className="max-width-11">Search</h1>
          <h3 className="max-width-11">Our Agency Partners</h3>
          <p className="max-width-11">
            Search by name, or filter for location, capabilities, industries and
            specializations.
          </p>

          <label className="search-label">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search..."
            />
            {searchQuery?.length > 2 ? (
              <FontAwesomeIcon
                icon={faClose}
                onClick={() => setSearchQuery("")}
              />
            ) : null}
          </label>
        </div>

        <div className="filter-wrap">
          <div className="filter-actions">
            <a className="apply-filters" onClick={() => handleApplyFilters()}>
              Apply
            </a>
            <a className="clear-filters" onClick={resetFilters}>
              Clear
            </a>
          </div>
          <div className="tabs">
            <Tabs>
              <TabList>
                <Tab>Locations</Tab>
                <Tab>Capabilities</Tab>
                <Tab>Industries</Tab>
                <Tab>Specialization</Tab>
              </TabList>

              <TabPanel>
                <div style={{ maxWidth: 320 }}>
                  <Select
                    options={countryOptions}
                    value={selectedCountries}
                    isMulti={true}
                    placeholder={"Search country"}
                    onChange={setselectedCountries}
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <ul className="tablist-wrap">
                  {capabilities?.map((capability, index) => (
                    <li
                      key={`capabilities_${index}`}
                      className={
                        selectedCapabilities.includes(index) ? "active" : ""
                      }
                    >
                      <a onClick={() => handleToggleActiveCapabilities(index)}>
                        {capability?.attributes?.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </TabPanel>
              <TabPanel>
                <ul className="tablist-wrap">
                  {industries?.map((industry, index) => (
                    <li
                      key={`industries_${index}`}
                      className={
                        selectedIndustries.includes(index) ? "active" : ""
                      }
                    >
                      <a onClick={() => handleToggleActiveIndustries(index)}>
                        {industry?.attributes?.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </TabPanel>
              <TabPanel>
                <ul className="tablist-wrap">
                  {specializations?.map((specialization, index) => (
                    <li
                      key={`specializations_${index}`}
                      className={
                        selectedSpecializations.includes(index) ? "active" : ""
                      }
                    >
                      <a
                        onClick={() => handleToggleActiveSpecializations(index)}
                      >
                        {specialization?.attributes?.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </TabPanel>
            </Tabs>
          </div>
        </div>
        {/* <div className="selected-opions-wrapper">
                    <h4>Your selection</h4>
                    <div className="selected-opions">
                        {selectedCountries?.length > 0 ? (
                            <div>
                                <span className="title">Countries : </span>
                                {selectedCountries?.map((country, index) => (
                                    <span>{country?.label}</span>
                                ))}
                            </div>
                        ) : null}
                    </div>
                </div> */}
        <div className="search-results">
          {isAgenciesDataLoading || isAgenciesDataFetching ? (
            <div className="spinner spinner-works">
              <span></span>
            </div>
          ) : (
            <>
              {agencies?.length > 0 ? (
                <div className="agency-results">{agencyList}</div>
              ) : (
                <p className="empty-results">No results found</p>
              )}
            </>
          )}


        </div>
      </div>
    </div>
  );
}

export default Agencies;
