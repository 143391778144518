import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faVimeo,
} from "@fortawesome/free-brands-svg-icons";
import {
  faArrowLeft,
  faArrowRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "components/Accordion";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import {
  useGetAgencyQuery,
  useGetAgencyWorksQuery,
} from "store/agencies/agenciesApi";
import { useGetClientsByAgencyQuery } from "store/helper/helperApi";
import { chunkArray } from "utils/functions";

function Agency() {
  const { id } = useParams();
  let sliderRef = useRef(null);

  const [selectedSpecialization, setselectedSpecialization] = useState("");
  const [selectedIndustry, setselectedIndustry] = useState("");

  const getFilterQueryString = () => {
    let baseUrl = "";

    if (selectedSpecialization) {
      baseUrl += `&filters[agency][title][$eqi]=${agency?.attributes?.title}&filters[specializations][title][$eqi]=${selectedSpecialization}`;
    }
    if (selectedIndustry) {
      baseUrl += `&filters[agency][title][$eqi]=${agency?.attributes?.title}&filters[industries][title][$eqi]=${selectedIndustry}`;
    }

    return baseUrl;
  };

  /** QUERIES */

  const {
    data: agency,
    error: agencyError,
    isLoading: isAgencyLoading,
  } = useGetAgencyQuery({ id });

  const {
    data: agencyWorks,
    error: agencyWorksError,
    isLoading: isAgencyWorksLoading,
  } = useGetAgencyWorksQuery(
    { title: agency?.attributes?.title },
    { skip: !agency?.attributes?.title }
  );

  const {
    data: agencyClients,
    isLoading: isAgencyClientsLoading,
    refetch: refetchClients,
    isFetching: isAgencyClientsFetching,
  } = useGetClientsByAgencyQuery(
    {
      query:
        getFilterQueryString() ||
        `filters[agency][title][$eqi]=${agency?.attributes?.title}`,
    },
    { skip: !agency?.attributes?.title }
  );

  console.log(agency);

  // refetch clients if agency data
  useEffect(() => {
    if (agency?.attributes?.title) {
      refetchClients();
    }
  }, [agency]);

  const handleClear = () => {
    setselectedIndustry("");
    setselectedSpecialization("");
  };

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    // dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: "ondemand",
  };

  const contactRows = agency?.attributes?.contact?.split("\n\n");

  // Map each contact to an object
  const sortedHeads = contactRows?.map((contact) => {
    const [name, department, email] = contact.split(",");
    return { name, department, email };
  });

  const departmentHeads = sortedHeads?.map(
    ({ name, department, email }, index) => (
      <div className="flex-1" key={`department-heads_${index}`}>
        <div className="card-item">{name}</div>
        <div className="card-item">{department}</div>
        <div className="card-item">{email}</div>
      </div>
    )
  );

  const specializationOptions = agency?.attributes?.specializations?.data?.map(
    (specialization, index) => {
      return (
        <option
          key={`specialization_option_${index}`}
          value={specialization?.attributes?.title}
        >
          {specialization?.attributes?.title}
        </option>
      );
    }
  );

  const industryOptions = agency?.attributes?.industries?.data?.map(
    (industry, index) => {
      return (
        <option
          key={`specialization_option_${index}`}
          value={industry?.attributes?.title}
        >
          {industry?.attributes?.title}
        </option>
      );
    }
  );

  const chunkedAgencyClients = chunkArray(agencyClients, 3);

  const clientList = chunkedAgencyClients?.map((chunk, chunkIndex) => (
    <ul key={`chunk_${chunkIndex}`} className="agency-client-column">
      {chunk.map((client, index) => (
        <li key={`agency-client_${chunkIndex * 3 + index}`}>
          <span >{client?.attributes?.title}</span>
        </li>
      ))}
    </ul>
  ))

  const agencyWorkSlides = agencyWorks?.map((work, index) => {
    //console.log(work);
    return (
      <div className="flex-slide-item" key={`work_slides${index}`}>
        <div className="left-copy">
          <div className="head-wrap">
            <p className="tag">Agency:</p>
            <p className="title">
              {work?.attributes?.agency?.data?.attributes?.title}
            </p>
          </div>
          <div className="head-wrap">
            <p className="tag">Client:</p>
            <p className="title">
              {work?.attributes?.client?.data?.attributes?.title}
            </p>
          </div>
          <div className="head-wrap">
            <p className="tag">Category:</p>
            <p className="title">
              {work?.attributes?.specialization?.data?.attributes?.title}
            </p>
          </div>
          <div className="head-wrap">
            <p className="tag">Country:</p>
            <p className="title">
              {work?.attributes?.country?.data?.attributes?.title}
            </p>
          </div>
          <Link className="link-project">See Project</Link>
        </div>
        <div className="img-slider">
          <img src={work?.attributes?.thumbnail?.data?.attributes?.url} />
        </div>
      </div>
    );
  });

  return (
    <>
      <section className="agency-wrapper container">
        <Link className="back-search" to={"/agencies"}>
          <span>
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>
          Back to search
        </Link>
        <div className="banner-wrapper">
          <div className="flex-copy">
            <h3>Partner Profile</h3>
            <h4>{agency?.attributes?.title}</h4>
            <p>{agency?.attributes?.overview}</p>
          </div>
          <div className="flex-logo">
            <img
              className="img-logo"
              src={agency?.attributes?.logo?.data?.attributes?.url}
              alt="logo"
            />
            <div className="center-line"></div>
            <div className="social-link-wrapper">
              {agency?.attributes?.title ? (
                <a
                  href={agency?.attributes?.website_link}
                  className="visit-link"
                  target="_blank"
                >
                  <span>Visit site</span>
                  <span>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </a>
              ) : null}

              {agency?.attributes?.facebook_link ? (
                <a
                  href={agency?.attributes?.facebook_link}
                  className="icon-link"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
              ) : null}

              {agency?.attributes?.instagram_link ? (
                <a
                  href={agency?.attributes?.instagram_link}
                  className="icon-link"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              ) : null}

              {agency?.attributes?.linkedin_link ? (
                <a
                  href={agency?.attributes?.linkedin_link}
                  className="icon-link"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              ) : null}

              {agency?.attributes?.twitter_link ? (
                <a
                  href={agency?.attributes?.twitter_link}
                  className="icon-link"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              ) : null}

              {agency?.attributes?.vimeo_link ? (
                <a href={agency?.attributes?.vimeo_link} className="icon-link" target="_blank">
                  <FontAwesomeIcon icon={faVimeo} />
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </section>
      <section className="acc-wrapper-section">
        <div className="wrapper-tabs container">

          {agency?.attributes?.capabilities?.data?.length > 0 ? (
            <Accordion title="Capabilities">
              {agency?.attributes?.capabilities?.data?.map((capability, index) => (
                <p key={`capabilities_title_${index}`}>{capability?.attributes?.title}</p>
              ))}
            </Accordion>
          ) : null}

          {agency?.attributes?.specializations?.data?.length > 0 ? (
            <Accordion title="Specializations">
              {agency?.attributes?.specializations?.data?.map((specialization, index) => (
                <p key={`specialization_title_${index}`}>{specialization?.attributes?.title}</p>
              ))}
            </Accordion>
          ) : null}

          {agency?.attributes?.industries?.data?.length > 0 ? (
            <Accordion title="Industries">
              {agency?.attributes?.industries?.data?.map((industry, index) => (
                <p key={`industries_title_${index}`}>{industry?.attributes?.title}</p>
              ))}
            </Accordion>
          ) : null}

          {agency?.attributes?.location ? (
            <Accordion title="Office Location">
              <p >{agency?.attributes?.location?.split("\n\n")?.[0]}</p>
            </Accordion>
          ) : null}

        </div>
      </section>
      <section className="department-section">
        <div className="department-wrapper container">
          <h3 className="heading-dpt">Department Heads</h3>
          <div className="flex-card">{departmentHeads}</div>
        </div>
      </section>
      <section className="agency-clients-section">
        <div className="agency-clients-wrapper container">
          <h3 className="heading-agcy">Agency Clients</h3>
          <div className="client-filters">
            <div className="select-wrapper">
              <select
                className="select-wrap"
                value={selectedSpecialization}
                onChange={(e) => setselectedSpecialization(e.target.value)}
              >
                <option value="" disabled>
                  Specializations
                </option>
                {specializationOptions}
              </select>
              <FontAwesomeIcon className="fevicon-arrow" icon={faChevronDown} />
            </div>
            <div className="select-wrapper">
              <select
                className="select-wrap"
                value={selectedIndustry}
                onChange={(e) => setselectedIndustry(e.target.value)}
              >
                <option value="" disabled>
                  Industries
                </option>
                {industryOptions}
              </select>
              <FontAwesomeIcon className="fevicon-arrow" icon={faChevronDown} />
            </div>

            <div className="cta-wrap">
              {/* <a className="btn-agcy">Apply</a> */}
              {(selectedIndustry || selectedSpecialization) ? (
                <a className="btn-clear-agcy" onClick={handleClear}>
                  Clear
                </a>
              ) : null}

            </div>
          </div>
          <div className="client-list">
            {isAgencyClientsLoading || isAgencyClientsFetching ? (
              <div className="spinner spinner-works">
                <span></span>
              </div>
            ) : (
              <>{agencyClients?.length > 0 ? (
                clientList
              ) : (
                <p>No results found.</p>
              )}</>
            )}
          </div>
        </div>
      </section>
      <section className="select-work-section">
        <div className="select-work-wrapper container">
          <h3 className="slider-head-copy">Select Work</h3>
          <div className="slider-container">
            <Slider
              ref={(slider) => {
                sliderRef = slider;
              }}
              {...settings}
            >
              {agencyWorkSlides}
            </Slider>
            <div className="control-wrappar" style={{ textAlign: "center" }}>
              <button className="button" onClick={previous}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <button className="button" onClick={next}>
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Agency;
