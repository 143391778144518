
const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array?.length; i += chunkSize) {
        result.push(array?.slice(i, i + chunkSize));
    }
    return result;
};

function trimIt(str) {
    // Remove spaces from both ends
    str = str.trim();

    // Remove extra spaces within the string
    str = str.replace(/\s+/g, ' ');

    return str;
}

export { chunkArray, trimIt };