import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useLoginUserMutation } from "store/auth/authApi";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required*"),
  password: Yup.string()
    .required("Password is required*")
    .min(6, "Password must be at least 6 characters")
    .matches(/[A-Z]/, "Password must have at least one uppercase character")
    .matches(/\d/, "Password must have at least one number")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must have at least one special character"
    ),
});

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [loginUser, { data, error, isLoading, isFetching }] =
    useLoginUserMutation();

  useEffect(() => {
    //console.log(data);
    if (error) {
      const { message } = error;
      toast({
        position: "top-right",
        title: "Error",
        description: message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
    if (data) {
      navigate(from, { replace: true });
    }
  }, [error, data, isFetching]);

  return (
    <div className="login-page">
      <div className="wrapper-login">
        <div className="hero-section ">
          <ToastContainer />
          <section className="hero-copy container">
            <h2>A CONNECTED COLAB</h2>
            <p>
              A platform empowering seamless collaboration between <br />{" "}
              companies and agencies throughout the world.
            </p>
          </section>
        </div>

        <div className="form-wrapper container">
          <h1 className="login-copy">Login</h1>
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              const { username, password } = values;
              const payload = {
                identifier: username,
                password,
              };
              if (values) {
                await loginUser(payload);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="err-inpt-wrap">
                  <Field
                    className="input-field"
                    type="text"
                    name="username"
                    placeholder="Username*"
                  />
                  <ErrorMessage
                    className="err-div"
                    name="username"
                    component="div"
                  />
                </div>
                <div className="err-inpt-wrap">
                  <Field
                    className="input-field"
                    type="password"
                    name="password"
                    placeholder="Password*"
                  />
                  <ErrorMessage
                    className="err-div"
                    name="password"
                    component="div"
                  />
                </div>
                <div className="login-footer">
                  {isFetching || isLoading ? (
                    <div className="spinner spinner-works">
                      <span></span>
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="btn login-submit-btn"
                      disabled={isSubmitting}
                    >
                      Login
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
          {/* <p className="forgot-copy">
            Forgot your password?{" "}
            <span>
              Click <Link to={"/reset-password"}>here</Link> to reset it.
            </span>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
