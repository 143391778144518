import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ResetPassword = () => {
  const initialValues = {
    email: "",
    username: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    username: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    console.log("Form data", values);
  };

  return (
    <div className="reset-page">
      <div className="wrapper-login">
        <div className="hero-section"></div>

        <div className="form-wrapper container">
          <h1 className="login-copy">Reset Your Login</h1>
          <p className="login-para">
            Enter your username or email address to reset your password. For any
            assistance please reach out.
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <Form>
                <div className="err-inpt-wrap">
                  {/* <label htmlFor="email">Email</label> */}
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    className="input-field"
                    placeholder="Email"
                  />
                  <ErrorMessage name="email" component="div" />
                </div>
                <div className="err-inpt-wrap">
                  {/* <label htmlFor="username">Username</label> */}
                  <Field
                    type="text"
                    id="username"
                    name="username"
                    className="input-field"
                    placeholder="User Name"
                  />
                  <ErrorMessage name="username" component="div" />
                </div>
                <button type="submit" className="btn login-submit-btn">
                  Reset Password
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
