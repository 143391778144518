import { api } from '../api';

export const agenciesApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAgencies: builder.query({
            query: ({ query }) => ({
                url: `/agencies/?${query}&populate=*`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response?.data,
        }),
        getAgency: builder.query({
            query: ({ id }) => ({
                url: `/agencies/${id}/?populate=*`,//`/works/?filters[slug][$eq]=${slug}&populate=*`
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        getAgencyWorks: builder.query({
            query: ({ title }) => ({
                url: `/works/?filters[agency][title][$eq]=${title}&populate=*`,//`/works/?filters[slug][$eq]=${slug}&populate=*`
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
    })
})

export const { useGetAgenciesQuery, useGetAgencyQuery, useGetAgencyWorksQuery } = agenciesApi;