import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const localUserInfo = JSON.parse(localStorage.getItem("cfUser"));
      if (localUserInfo) {
        headers.set("Authorization", `Bearer ${localUserInfo.jwt}`);
        return headers;
      }
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  }),
  // refetchOnFocus: true, // refetch on window focus
  //refetchOnReconnect: true, // refetch on internet connection
  //keepUnusedDataFor: 86400, // refresh cache if unused for 24 hrs
  tagTypes: [
    //"Users",
  ],
  endpoints: () => ({}),
});
