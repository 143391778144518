import { api } from '../api';

export const helperApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCountries: builder.query({
            query: () => ({
                url: `/countries/?populate=*`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response?.data,
        }),
        getCapabilities: builder.query({
            query: () => ({
                url: `/capabilities/?populate=*`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response?.data,
        }),
        getSpecialization: builder.query({
            query: () => ({
                url: `/specializations/?populate=*`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response?.data,
        }),
        getIndustries: builder.query({
            query: () => ({
                url: `/industries/?populate=*`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response?.data,
        }),
        getClientsByAgency: builder.query({
            query: ({ query }) => ({
                url: `/clients/?${query}`,//`/works/?filters[slug][$eq]=${slug}&populate=*`
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
    })
})

export const { useGetCapabilitiesQuery, useGetCountriesQuery, useGetSpecializationQuery, useGetIndustriesQuery, useGetClientsByAgencyQuery } = helperApi;